import TrendingNow from './Trendingpage'
import PopularNow from './Popularpage'

export default function Homepage() {


  return (
    <>
    <TrendingNow/>
    <PopularNow/>
    </>
  )
}
