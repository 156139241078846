import React, { useEffect, useState } from 'react';
import { GraphQLClient, gql } from 'graphql-request';
import { Link } from 'react-router-dom';  // Import Link for navigation
import FilterBar from '../components/FilterBar';
import { stripHtml } from '../utils/stripHtml';
import { RiSignalWifiErrorLine } from "react-icons/ri";
const endpoint = 'https://graphql.anilist.co';  

const client = new GraphQLClient(endpoint);

const query = gql`
  query {
    Page(perPage: 24) {
      trending: media(sort: TRENDING_DESC, type: ANIME) {
        id
        title {
          romaji
          english
        }
        format
        duration
        tags {
          name
        }
        coverImage {
          extraLarge
          large
          medium
          color
        }
      }
      popular: media(sort: POPULARITY_DESC, type: ANIME) {
        id
        title {
          romaji
          english
        }
        format
        duration
        tags {
          name
        }
        coverImage {
          extraLarge
          large
          medium
          color
        }
      }
    }
  }
`;

function Tooltip({ anime }) {
  return (
    <div className="absolute left-0 top-0 p-2  bg-black bg-opacity-50  w-full h-full shadow-lg rounded-t-lg   text-sm z-40">
      <h4 className="font-bold text-white">{stripHtml(anime.title.english || anime.title.romaji)}</h4>
      <p className="font-semibold text-white">{stripHtml(anime.format)}</p>
      <p className="font-semibold text-white">{anime.duration} min</p>
      <div className="flex flex-wrap gap-1 mt-2 text-justify">
        {anime.tags.slice(0, 3).map((tag) => (
          <span key={tag.name} className="text-xs bg-gray-200 rounded-full px-2 py-1">
            {stripHtml(tag.name)}
          </span>
        ))}
      </div>

    </div>
  );
}

export default function Movie() {
  const [trendingAnime, setTrendingAnime] = useState([]);
  const [filteredAnime, setFilteredAnime] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [hoveredAnime, setHoveredAnime] = useState(null);
  const [searchTerm, setSearchTerm] = useState('');
  const [selectedGenre, setSelectedGenre] = useState('Any');

  useEffect(() => {
    client.request(query)
      .then((data) => {
        setTrendingAnime(data.Page.trending);
        setFilteredAnime(data.Page.trending);
        setLoading(false);
      })
      .catch((err) => {
        setError(<RiSignalWifiErrorLine  size={"100px"}/>);
        setLoading(false);
      });
  }, []);

  const handleSearch = (term) => {
    setSearchTerm(term);
    filterAnime(term, selectedGenre);
  };

  const handleGenreChange = (genre) => {
    setSelectedGenre(genre);
    filterAnime(searchTerm, genre);
  };

  const filterAnime = (searchTerm, genre) => {
    const filtered = trendingAnime.filter(anime => {
      const matchesSearchTerm = stripHtml(anime.title.romaji).toLowerCase().includes(searchTerm.toLowerCase()) || 
                                stripHtml(anime.title.english).toLowerCase().includes(searchTerm.toLowerCase());
      const matchesGenre = genre === 'Any' || anime.tags.some(tag => stripHtml(tag.name) === genre);
      return matchesSearchTerm && matchesGenre;
    });
    setFilteredAnime(filtered);
  };

  if (loading) {
    return (
      <div className="flex justify-center items-center min-h-screen">
        <div className="loadinganimasi"></div>
      </div>
    );
  }

  if (error) {
    return (
      <div className="flex justify-center items-center min-h-screen text-red-500">
        {error}
      </div>
    );
  }

  return (
    <>
      <FilterBar onSearch={handleSearch} onGenreChange={handleGenreChange} />
      <div className="container mx-auto p-4 pt-15">
        <section>
          <h2 className="text-2xl font-bold mb-4">Movie & TV Series</h2>
          <div className="grid grid-cols-2 md:grid-cols-4 gap-4 relative">
            {filteredAnime.length === 0 ? (
              <div className="text-center py-8 text-gray-500">
                No results found
              </div>
            ) : (
              filteredAnime.map((anime) => (
                <Link
                  key={anime.id}
                  to={`/detail/${anime.id}`} 
                  className="bg-white shadow-md rounded-lg overflow-hidden relative"
                  onMouseEnter={() => setHoveredAnime(anime.id)}
                  onMouseLeave={() => setHoveredAnime(null)}
                >
                  <img
                    src={anime.coverImage.large}
                    alt={stripHtml(anime.title.romaji)}
                    className="w-full h-64 object-cover"
                  />
                 
                  {hoveredAnime === anime.id && <Tooltip anime={anime} />}
                </Link>
              ))
            )}
          </div>
        </section>
      </div>
    </>
  );
}
