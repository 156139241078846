import React from "react";
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import RootLayout from './layouts/RootLayout';
import Header from "./components/Header";
import Homepage from "./pages/Homepage";
import Movie from "./pages/Moviepage";
import LoginPage from "./pages/LoginPage";
import Detail from "./pages/Detail";
import MostAnime from "./pages/MostAnimepage";
import MostManga from "./pages/MostMangapage";


function App() {
  return (
  <Router>
    <RootLayout element={<Header/>}>
    <Routes>
    <Route path="/" element={<Homepage/>} />
    <Route path="/movie" element={<Movie />} />
    <Route path="/detail/:id" element={<Detail/>} />
    <Route path="/mostmanga" element={<MostManga/>} />
    <Route path="/mostanime" element={<MostAnime/>} />
    <Route path="/login" element={<LoginPage/>} />
    </Routes>

    </RootLayout>
  </Router>
  );
}

export default App;
