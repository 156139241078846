import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { GraphQLClient, gql } from 'graphql-request';
import { stripHtml } from '../utils/stripHtml';  
import { RiSignalWifiErrorLine } from "react-icons/ri";
const endpoint = 'https://graphql.anilist.co';  
const client = new GraphQLClient(endpoint);

var query = gql`
  query ($id: Int) {
    Media(id: $id) {
      id
      title {
        romaji
        english
      }
      description
      format
      duration
      tags {
        name
      }
      coverImage {
        extraLarge
        large
        medium
        color
      }
      staff {
        edges {
          node {
            id
            name {
              full
              native
            }
          }
        }
      }
    }
  }
`;

export default function Detail() {
  const { id } = useParams();  
  const [anime, setAnime] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    client.request(query, { id: parseInt(id) })
      .then((data) => {
        setAnime(data.Media);
        setLoading(false);
      })
      .catch((err) => {
        setError(<RiSignalWifiErrorLine size={"80px"} />);
        setLoading(false);
      });
  }, [id]);

  if (loading) {
    return (
      <div className="flex justify-center items-center min-h-screen">
        <div className="loadinganimasi border-t-4 border-blue-500 border-solid rounded-full w-16 h-16 border-4"></div>
      </div>
    );
  }

  if (error) {
    return (
      <div className="flex justify-center items-center min-h-screen text-red-500 text-xl font-semibold">
        {error}
      </div>
    );
  }

  if (!anime) {
    return (
      <div className="flex justify-center items-center min-h-screen text-gray-600 text-xl font-semibold">
        <p>No anime found</p>
      </div>
    );
  }

  return (
    <div className="container mx-auto p-4 pt-32">
      <div className="flex flex-col md:flex-row bg-white shadow-lg rounded-lg overflow-hidden">
        <img
          src={anime.coverImage.large}
          alt={stripHtml(anime.title.romaji)}
          className="w-full h-auto object-cover transform transition duration-300 group-hover:scale-105"
        />  
        <div className="p-6 md:ml-6">
          <h1 className="text-4xl font-bold mb-4 text-gray-800">
            {stripHtml(anime.title.english || anime.title.romaji)}
          </h1>
          <div className="mb-4">
            <h2 className="text-2xl font-semibold mb-2 text-gray-700">Description:</h2>
            <p className="text-gray-600">{stripHtml(anime.description)}</p>
          </div>
          <p className="text-lg mb-2 text-gray-700">Format: {stripHtml(anime.format)}</p>
          <p className="text-lg mb-2 text-gray-700">Duration: {anime.duration} min</p>
          <div className="mb-4">
            <h2 className="text-2xl font-semibold mb-2 text-gray-700">Tags:</h2>
            <div className="flex flex-wrap gap-2">
              {anime.tags.map((tag) => (
                <span key={tag.name} className="text-xs bg-gray-200 text-gray-800 rounded-full px-3 py-1">
                  {stripHtml(tag.name)}
                </span>
              ))}
            </div>
          </div>
          <div className="mb-10">
          <h2 className="text-2xl font-semibold mb-2 text-gray-700">Staff:</h2>
          <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5 gap-6">
          {anime.staff.edges.map(({ node }) => (
            <div
              className="relative bg-white shadow-lg rounded-lg overflow-hidden cursor-pointer group transform transition duration-300 hover:scale-105"
              key={node.id}
            >
              <div className="p-4">
                <h3 className="text-lg font-semibold text-gray-800 truncate">
                  {stripHtml(node.name.full)}
                </h3>
              </div>
            </div>
          ))}
        </div>

        </div>

        </div>
      </div>
    </div>
  );
}
