import React, { useEffect, useState } from 'react';
import { GraphQLClient, gql } from 'graphql-request';
import { RiSignalWifiErrorLine } from "react-icons/ri";
const endpoint = 'https://graphql.anilist.co';
const client = new GraphQLClient(endpoint);

const query = gql`
  query ($page: Int, $perPage: Int) {
    Page(page: $page, perPage: $perPage) {
      pageInfo {
        total
        currentPage
        lastPage
        hasNextPage
      }
      popular: media(sort: POPULARITY_DESC, type: ANIME) {
        id
        title {
          romaji
          english
        }
        coverImage {
          extraLarge
          large
          medium
          color
        }
        description
        genres
        episodes
        status
      }
    }
  }
`;

const stripHTMLTags = (str) => {
  if (!str) return '';
  return str.replace(/<[^>]*>/g, '');
};

const PopularNow = () => {
  const [animeList, setAnimeList] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [hasNextPage, setHasNextPage] = useState(false);
  const [selectedAnime, setSelectedAnime] = useState(null);
  const [showFullDescription, setShowFullDescription] = useState(false);
  const perPage = 5;

  useEffect(() => {
    setLoading(true);
    setError(null);
    client.request(query, { page: currentPage, perPage })
      .then((data) => {
        setAnimeList(data.Page.popular);
        setHasNextPage(data.Page.pageInfo.hasNextPage);
        setLoading(false);
      })
      .catch((err) => {
        setError(<RiSignalWifiErrorLine  size={"50px"}/>);
        setLoading(false);
      });
  }, [currentPage]);

  const handleNextPage = () => {
    if (hasNextPage) setCurrentPage(currentPage + 1);
  };

  const handlePreviousPage = () => {
    if (currentPage > 1) setCurrentPage(currentPage - 1);
  };

  const handleAnimeClick = (anime) => {
    setSelectedAnime(anime);
  };

  const handleCloseModal = () => {
    setSelectedAnime(null);
  };

  const handleToggleDescription = () => {
    setShowFullDescription(!showFullDescription);
  };

  if (loading) {
    return (
      <div className="flex justify-center items-center min-h-screen">
        <div className="loadinganimasi"></div>
      </div>
    );
  }

  if (error) {
    return (
      <div className="flex justify-center items-center min-h-screen text-red-500">
        {error}
      </div>
    );
  }

  return (
    <div className="container mx-auto p-4">
      <div className="flex justify-between items-center mb-4">
        <h2 className="text-2xl font-bold">Popular Now</h2>
      </div>
      <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5 gap-4">
        {animeList.map((anime) => (
          <div
            key={anime.id}
            className="relative bg-white shadow-md rounded-lg overflow-hidden cursor-pointer group"
            onClick={() => handleAnimeClick(anime)}
          >
            <img
              src={anime.coverImage.extraLarge}
              alt={anime.title.romaji}
              className="w-full h-64 object-cover"
            />
            <div className="absolute inset-0 bg-black bg-opacity-70 text-white opacity-0 group-hover:opacity-100 transition-opacity duration-300 flex flex-col justify-start items-start p-4">
              <h3 className="text-lg font-bold">{anime.title.english}</h3>
              <p className="text-sm mt-2">
                {stripHTMLTags(anime.description).length > 100
                  ? `${stripHTMLTags(anime.description).substring(0, 100)}...`
                  : stripHTMLTags(anime.description)}
              </p>
              <p className="mt-2 text-xs">
                Genres: {anime.genres.join(', ')}
              </p>
            </div>
          </div>
        ))}
      </div>

      {/* Pagination Controls */}
      <div className="flex justify-between mt-4">
        <button
          onClick={handlePreviousPage}
          disabled={currentPage === 1}
          className="px-4 py-2 bg-gray-300 text-gray-700 rounded-md disabled:opacity-50"
        >
          Previous
        </button>
        <button
          onClick={handleNextPage}
          disabled={!hasNextPage}
          className="px-4 py-2 bg-blue-500 text-white rounded-md disabled:opacity-50"
        >
          Next
        </button>
      </div>

      {/* Modal for Detailed View */}
      {selectedAnime && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50">
          <div className="relative bg-white p-4 rounded-lg w-full max-w-4xl mx-4 sm:mx-0 max-h-[90vh] overflow-auto">
            <button
              onClick={handleCloseModal}
              className="absolute top-2 right-2 w-8 h-8 flex items-center justify-center text-gray-600 hover:text-gray-900 focus:outline-none"
              aria-label="Close"
            >
              <span className="text-xl md:text-2xl">&times;</span>
            </button>
            <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
              
              <div className="bg-gray-200 p-2 flex justify-center items-center">
                <img
                  src={selectedAnime.coverImage.extraLarge}
                  alt={selectedAnime.title.english}
                  className="w-full h-auto max-h-80 object-contain rounded-lg"
                />
              </div>

           
              <div className="p-4 flex flex-col justify-between">
                <div>
                  <h3 className="text-xl md:text-2xl font-bold">{selectedAnime.title.romaji}</h3>
                  <p className="text-base md:text-lg text-gray-600">{selectedAnime.title.english}</p>
                  <p className="mt-2 text-sm md:text-base">
                    {showFullDescription
                      ? selectedAnime.description
                      : `${selectedAnime.description.substring(0, 200)}...`}
                  </p>
                  {selectedAnime.description.length > 200 && (
                    <button
                      onClick={handleToggleDescription}
                      className="text-blue-500 mt-2"
                    >
                      {showFullDescription ? 'Read Less' : 'Read More'}
                    </button>
                  )}
                  <p className="mt-2 text-sm md:text-base"><strong>Genres:</strong> {selectedAnime.genres.join(', ')}</p>
                  <p className="mt-2 text-sm md:text-base"><strong>Episodes:</strong> {selectedAnime.episodes}</p>
                  <p className="mt-2 text-sm md:text-base"><strong>Status:</strong> {selectedAnime.status}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default PopularNow;
