const FOOTERDATALINK = [
    {
        title: "Donasi",
        items: ["Bikin Bareng", "Kopdar Bareng", "Nobar Bareng"],
    },
    {
        title: "Anime Flow",
        items: ["Press Anime", "News Anime"],
    },
    {
        title: "Resource",
        items: ["Blog", "Events Cosplay Anime"],
    },
];

const tahunFooter = new Date().getFullYear();

export function Footer() {
    return (
        <>
            <footer className="relative w-full bg-blue-950 p-5">
                <div className="mx-auto max-w-7xl w-full px-8">
                    <div className=" md:grid-cols-2 grid grid-cols-1 justify-between gap-4">
                        <h1 className="font-extrabold text-white">Anime Flow</h1>
                        <div className="justify-between grid grid-cols-3  gap-4">
                            {FOOTERDATALINK.map(({ title, items }, index) => (
                                <ul key={index}>
                                    {title && (
                                        <div className="font-medium  mb-3  text-white">{title}</div>
                                    )}
                                    {items.map((link, idx) => (
                                        <li key={idx}>
                                            <a
                                                href="#"
                                                className="text-white py-1.5 transition-colors
                                                font-normal  hover:text-blue-gray-900"
                                            >
                                                {link}
                                            </a>
                                        </li>
                                    ))}
                                </ul>
                            ))}
                        </div>
                    </div>
                    <div className="mt-12 flex w-full flex-col items-center justify-center border-t border-blue-gray-50 py-4 md:flex-row md:justify-between">
                        <div className="font-bold text-white  md:mb-0 mb-4 text-center ">
                            &copy; {tahunFooter}{" "}
                            <a href="">
                               Muhamad Dendi Purwanto
                            </a>
                            . All Rights Reserved.
                        </div>
                     
                    </div>
                </div>
            </footer>
        </>
    );
}
