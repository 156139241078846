import React, { useState, useEffect } from 'react';
import { FcSearch } from "react-icons/fc";

const FilterBar = ({ onSearch }) => {
  const [PencarianTrem, setPencarianTrem] = useState('');
  const [cariGenre, setCariGenre] = useState('Any');

  const handlePencarian = () => {
    onSearch?.(PencarianTrem, cariGenre); // Gunakan optional chaining untuk keamanan
  };

  const handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      handlePencarian();
    }
  };

  useEffect(() => {
    // Hanya panggil onSearch jika searchTerm kosong dan onSearch ada
    if (!PencarianTrem && onSearch) {
      onSearch('', cariGenre);
    }
  }, [PencarianTrem, cariGenre, onSearch]); 

  return (
    <div className="flex flex-wrap justify-around p-4 pt-32 bg-white rounded-lg space-y-4 md:space-y-0">
      {/* Pencariaan Input */}
      <div className="flex items-center space-x-5 w-full md:w-auto">
        <label htmlFor="search" className="text-gray-700">
          Search
        </label>
        <input
          type="text"
          id="search"
          value={PencarianTrem}
          onChange={(e) => setPencarianTrem(e.target.value)}
          onKeyDown={handleKeyDown}
          placeholder="Masukan Kata Kunci" 
          className="w-full md:w-80 p-2 border border-gray-300 rounded-md focus:outline-none focus:border-blue-500"
        />
      </div>

      

      {/* Filter Icon Button */}
      <button
        onClick={handlePencarian}
        className="bg-gray-300 hover:bg-gray-400 p-2 rounded-full w-full md:w-auto"
      >
        <FcSearch size={'20px'} />
      </button>
    </div>
  );
};

export default FilterBar;