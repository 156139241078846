import React, { useEffect, useState } from 'react';
import { GraphQLClient, gql } from 'graphql-request';
import { useNavigate } from 'react-router-dom';
import { RiSignalWifiErrorLine } from "react-icons/ri";
const endpoint = 'https://graphql.anilist.co';
const client = new GraphQLClient(endpoint);

const query = gql`
  query ($page: Int, $perPage: Int) {
    Page(page: $page, perPage: $perPage) {
      pageInfo {
        total
        currentPage
        lastPage
        hasNextPage
      }
      popular: media(sort: POPULARITY_DESC, type: MANGA) {
        id
        title {
          romaji
          english
        }
        coverImage {
          extraLarge
          color
        }
        description
        genres
        episodes
        status
      }
    }
  }
`;

const stripHTMLTags = (str) => {
  if (!str) return '';
  return str.replace(/<[^>]*>/g, '');
};

const MostManga = () => {
  const [animeList, setAnimeList] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [hasNextPage, setHasNextPage] = useState(false);
  const navigate = useNavigate();
  const perPage = 20;

  useEffect(() => {
    setLoading(true);
    setError(null);
    client.request(query, { page: currentPage, perPage })
      .then((data) => {
        setAnimeList(data.Page.popular);
        setHasNextPage(data.Page.pageInfo.hasNextPage);
        setLoading(false);
      })
      .catch((err) => {
        setError(<RiSignalWifiErrorLine size={"80px"} />);
        setLoading(false);
      });
  }, [currentPage]);

  const handleNextPage = () => {
    if (hasNextPage) setCurrentPage(currentPage + 1);
  };

  const handlePreviousPage = () => {
    if (currentPage > 1) setCurrentPage(currentPage - 1);
  };

  const handleAnimeClick = (id) => {
    navigate(`/detail/${id}`);
  };

  if (loading) {
    return (
      <div className="flex justify-center items-center min-h-screen">
        <div className="loadinganimasi"></div>
      </div>
    );
  }

  if (error) {
    return (
      <div className="flex justify-center items-center min-h-screen text-red-500">
        {error}
      </div>
    );
  }

  return (
    <div className="container mx-auto p-4 pt-32">
      <div className="flex justify-between items-center mb-4">
        <h2 className="text-2xl font-bold">Most Popular Manga</h2>
      </div>
      <div className="grid grid-cols-2 sm:grid-cols-3 md:grid-cols-4 lg:grid-cols-5 gap-4">
        {animeList.map((anime) => (
          <div
            key={anime.id}
            className="relative bg-white shadow-md rounded-lg overflow-hidden cursor-pointer group"
            onClick={() => handleAnimeClick(anime.id)}
          >
            <img
              src={anime.coverImage.extraLarge}
              alt={anime.title.romaji}
              className="w-full h-64 object-cover"
            />
            <div className="absolute inset-0 bg-black bg-opacity-70 text-white opacity-0 group-hover:opacity-100 transition-opacity duration-300 flex flex-col justify-start items-start p-4">
              <h3 className="text-lg font-bold">{anime.title.english}</h3>
              <p className="text-sm mt-2">
                {stripHTMLTags(anime.description).length > 100
                  ? `${stripHTMLTags(anime.description).substring(0, 100)}...`
                  : stripHTMLTags(anime.description)}
              </p>
              <p className="mt-2 text-xs">
                Genres: {anime.genres.join(', ')}
              </p>
            </div>
          </div>
        ))}
      </div>

      <div className="flex justify-between mt-4">
        <button
          onClick={handlePreviousPage}
          disabled={currentPage === 1}
          className="px-4 py-2 bg-gray-300 text-gray-700 rounded-md disabled:opacity-50"
        >
          Previous
        </button>
        <button
          onClick={handleNextPage}
          disabled={!hasNextPage}
          className="px-4 py-2 bg-blue-500 text-white rounded-md disabled:opacity-50"
        >
          Next
        </button>
      </div>
    </div>
  );
};

export default MostManga;
